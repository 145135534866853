@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Gilroy";
    src: url('static/fonts/Gilroy-Bold.ttf');
}

@font-face {
    font-family: "Gilroy-Light";
    src: url('static/fonts/Gilroy-Light.otf');
}

@font-face {
    font-family: 'Tuffy Bold';
    src: local('Tuffy Bold'), url('static/fonts/Tuffy-Bold.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');

:root {
    --dark: #999;
    --darker: #666;
    --darkest: #333;
    --lightblue: #84c4d3;
    --lighterblue: #8cf5f0;
}

.copyright {
    margin-left: 200px;
    margin-top: 50px;
}

.header {
    width: 100vw;
    position: fixed;
    z-index: 999;
    background-color: #262626;
    color: rgb(214, 214, 214);
    min-height: 50px;
    top: 0px;
    display: flex;
    justify-content: space-between;
    /*place-content:center;*/
}

.footer {
    background-color: #262626;
    color: rgb(214, 214, 214);
    width: 100vw;
    position: fixed;
    z-index: 999;
    min-height: 50px;
    bottom: 0px;
    justify-content: space-between;
    text-align: center;
    padding-top: 10px;
}

.footer div {
    display: inline-block;
}

.header div {
    display: inline-block;
}

html,
body {
    background-color: #f4f0ec;
    margin: 0;
    /*min-width: 600px;*/
}

body {
    font-weight: 400;
    color: #262626;
    overflow: auto;
}

.logo {
    align-self: center;
    padding-left: 2em;
}

.logo h3 {
    color: rgb(214, 214, 214);
    margin: 0;
}

.header-links {
    font-family: "Ropa Sans";
    font-size: 22px;
    align-self: center;
    padding-right: 2em;
    padding-top: 4px;
}

.forgotten-password {
    line-height: 30px;
}

.header-link {
    margin-left: 1em;
    cursor: pointer;
    color: white;
}

.selected-header-link {
    color: #e15330;
    margin-left: 1em;
    cursor: pointer;
}

.aboutDiv {
	margin: 0 auto;
	margin-bottom: 10px;
}

.aboutDivBullet {
	margin: 0 auto;
	margin-bottom: 5px;
}

.aboutDivAuthors {
	margin: 0 auto;
	margin-top: 0px;
	margin-bottom: 10px;
}

a {
    color: #e15330;
}

.header-link a {
    color: #eee;
}

.header-link a:hover {
    color: #999;
}

.selected-header-link a {
    color: #e15330;
}

.selected-header-link a:hover {
    color: #e15330;
}

.wrapper {
    display: grid;
    margin-top: -50px;
    grid-template-columns: 4-00px 1fr;
    grid-template-rows: 150px 1fr;
    gap: 10px;
    grid-template-areas: ". . " "a a";
}

.content-home {
    margin: auto;
    margin-top: 80px;
    min-height: 27em;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    width: 800px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.6);
    border-style: solid;
    border: 4px solid #e15330;
    border-radius: 13px;
}

.content-home .button-green-bigger {
    margin-left: 15px;
}


.content {
    min-width: 800px;
    grid-area: a;
    align-self: center;
    justify-self: center;
}

.titletext {
    width: 50%;
    margin: 0 auto;
    margin-left: 15px;
}


.content-small {
    min-width: 400px;
}

.content-smallest {
    min-width: 300px;
}

.block-wrapper {
    padding: 22vh 20% 0 20%;
}

form a {
    font-size: 12px;
    font-weight: 400;
}

.itemBoxWrapper {
    display: flex;
    background-color: #f4f0ec;
}

.admin {
    max-width: 1500px;
    min-width: 1500px;
    margin: auto;
}

.exams {
    max-width: 1300px;
    margin: auto;
}

.itemBox {
    flex: 1 0 21%;
    margin: 25px;
    height: 60vh;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 20px;
    font-weight: 600;
    overflow-y: scroll;
    overflow-y: auto;
}

.itemBox::-webkit-scrollbar {
    width: 15px;
}

.itemBox::-webkit-scrollbar-track {
    --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 10px;
}

.itemBox::-webkit-scrollbar-thumb {
    border: solid 5px #fff;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

.item {
    padding: 5px 10px;
    margin: 5px 0;
    display: flex;
}

.item div {
    padding: 5px 5px;
    margin: 2px 5px 2px 0;
    border-radius: 5px;
    border: 2px solid #262626;
    cursor: pointer;
}

.itemName {
    -width: 75%;
    flex-grow: 1;
    padding: 5px 10px !important;
    background-color: #fdfdfd;
    display: flex;
}

.deleteItem {
    background-color: #e15330;
}

.editItem {
    background-color: #4e6e4d;
}

.itemName:hover {
    background-color: #e6efe8;
}

.selectedItem {
    background-color: #cde1d1 !important;
}

.addNew {
    position: absolute;
    bottom: 2vh;
    background-color: #cde1d1;
    width: 20%;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    padding: 10px;
    opacity: 0.7;
}

.addNew:hover {
    background-color: #cde1d1;
    opacity: 1;
}

.lowOpacity {
    opacity: 0.3;
}

.login-wrapper {
    margin: 80px auto 0;
    min-height: 398px;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.493);
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    border: 4px solid #e15330;
}

.about-wrapper {
    margin: 90px 150px 80px;
    min-height: 550px;
    width: 100%;
    max-width: 1250px;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.493);
    padding-right: 20px;
    border-radius: 22px;
    border: 4px solid #e15330;
}

.regular-wrapper {
    margin-top: 35px;
    min-height: 27em;
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    background-color: rgba(255, 255, 255, 0.493);
    border-radius: 1em;
    max-width: 700px;
    padding: 20px;
}

.login-image-placeholder {
    background-repeat: repeat-x;
    background-size: 95%;
    display: inline-block;
    width: calc(50% + 200px);
    height: 620px;
    margin-top: 100px;
    left: 50%;
    transform: translateX(calc(40% - 150px));
}

.loginbackground {
    background-color: #acb1a2;
}

.center-image {
    background-repeat: no-repeat;
    background-size: 95%;
    height: 25em;
    display: inline-block;
    max-width: 550px;
    margin-left: 5px;
    width: 45%;
    height: auto;
    min-height: 100%;
    margin-top: 5px;
    opacity: 1;
    display: none;
}

.endurgjof-image-placeholder {
    transform: scaleX(-1);
    background-repeat: no-repeat;
    height: 25em;
    display: inline-block;
    max-width: 550px;
    width: 40%;
    min-height: 100%;
    background-position: center;
    background-size: 100%;
    opacity: 1.0;
    mix-blend-mode: multiply;
}

.login-form {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
    padding: 1em 3em;
}

.welcome {
    margin-top: 65px;
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
    color: #363636;
}

.login-form input {
    background-color: #fff;
    border: 2px solid #262626;
    color: #262626;
    padding: .5em .9em;
    border-radius: 0.2em;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
}

input {
    background-color: #fff;
    border: 2px solid #262626;
    color: #262626;
    padding: .5em .9em;
    border-radius: 0.2em;
    margin-bottom: .5em;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
}

textarea {
    background-color: #fff;
    border: 2px solid #262626;
    color: #262626;
    padding: .5em .9em;
    border-radius: 0.2em;
    margin-bottom: .5em;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
}

select {
    background-color: #fff;
    border: 2px solid #262626;
    color: #262626;
    padding: .5em .9em;
    border-radius: 0.2em;
    margin-bottom: .5em;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
}

input[type=submit] {
    background-color: #cde1d1;
    border: 0;
    font-family: 'Gilroy';
    font-size: 18px;
    padding: 6px 10px 6px 10px;
    text-transform: uppercase;
    color: #4e6e4d;
}

input[type=submit]:hover {
    background-color: #e15330;
    color: white;
    cursor: pointer;
}

.dangerText {
    color: red;
}

input {
    outline: none;
}

span {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    width: 95%;
}

.about-wrap {
    flex: 1;
    margin-top: 10px;
    margin-right: 25px;
    margin-left: 25px;
}

.website-info {
    font-size: 16px;
    width: 95%;
}

.aboutText {
    text-transform: uppercase;
    font-family: "Gilroy";
    font-size: 26px;
    margin-bottom: 10px;
    color: #e15330;
}

h3,
h4,
h5 {
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
    color: #e15330;
}

h3 {
    margin-top: 14px;
    font-size: 24px;
    margin-bottom: 7px;
}

input {
    filter: unset !important;
}

h4 {
    font-size: 18px;
}

.create-account-link {
    color: #4e6e4d;
    font-size: 20px;
    line-height: 3em;
    margin-top: 25px;
}

label {
    font-size: 0.8em;
    font-weight: 400;
    color: #424242;
    font-family: 'Work Sans', sans-serif;
    margin: 2px 0 4px 5px;
    display: inline-block;
}

.book-grid {
    display: grid;
    padding: 22vh 20% 0 20%;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
}

.book-table {
    margin-top: 30px;
}

#root {
    height: 100%;
    width: 100%;
}

.endurgjof-display {
    margin-top: 20px;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    align-self: center;
    font-weight: normal;
    padding: 10px 0px 10px 0px;
    color: #e15330;
}

.levelinfo {
    position: absolute;
    margin-left: 30px;
}

.levelheader {
    position: absolute;
    margin-top: -40px;
    margin-left: 0px;
    width: 100px;
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
    color: #363636;
    font-size: 18px;
}

.leveldescription {
    position: absolute;
    margin-top: 70px;
    margin-left: -170px;
    font-style: italic;
    width: 110px;
    font-weight: normal;
    margin-bottom: 10px;
    color: #363636;
    font-size: 16px;
}

.books-display {
    margin-top: 155px;
    margin-left: 100px;
    display: grid;
    grid-template-columns: repeat(4, 250px);
    /*grid-template-rows: 250px;*/
    grid-gap: 6em;
    align-self: center;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding-bottom: 3em;
}

.book-card {
    background-image: url(static/book-effect.png);
    text-align: center;
    width: 100%;
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0.3) 1px solid;
    cursor: pointer;
    transition: 0.2s;
}

.book-card:hover {
    transform: scale(1.05);
}

.book-card h1 {
    width: 75%;
    font-size: 1.8em;
    font-weight: 400;
    font-family: 'Ropa Sans', sans-serif;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.7);
}

.green a:link {
    color: #fff;
}

.red {
    background-color: #e15330;
}

.blue {
    background-color: #84c4d3;
}

.yellow {
    background-color: #fec260;
}

.purple {
    background-color: #986d8e;
}

.pink {
    background-color: #ffa0a0;
}

.green {
    background-color: #4e6e4d;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    margin: 0;
    list-style-type: none;
}

input[type=submit]:disabled {
    background-color: var(--lightblue);
}

.third-button {
    cursor: pointer;
    background-color: #af64ec;
    border-color: #af64ec;
    font-family: 'Gilroy';
    font-size: 18px;
    padding: 6px 10px 6px 10px;
    text-transform: uppercase;
    color: #fff;
}


#gradeAssignments {
    margin-left: 20px;
    margin-top: 40px;
}

.link-list {
    color: #262626;
    line-height: 25px;
    font-size: 15px;
}

h1 {
    margin-top: 15px;
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
    color: #363636;
}

.kafli-list {
    background-color: #cde1d1;
    scale: 1;
    min-width: 400px;
    max-width: 400px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition-duration: 1s;
}

.kafli-list-content {
    display: grid;
    font-weight: bold;
    display: grid;
}

.kafli-title {
    align-self: center;
}

.kafli-number {
    font-size: 30px;
    align-self: center;
    padding: 10px 0px 10px 15px;
    color: #e15330;
}

.kafli-info {
    font-size: 12px;
    align-self: center;
}

.desc {
    margin-bottom: 20px;
}

.chapterWrapper .small-button,
.chaptersWrapper .small-button,
.small-button-reading {
    background-color: #262626;
    border-radius: 5px;
    padding: 8px 18px 8px 18px;
    color: rgb(226, 226, 226);
    font-weight: 700;
    font-size: 16px;
    margin: 0 15px 20px auto;
}

.small-button-reading:hover {
    color: rgb(226, 226, 226);
}

.chaptersWrapper .small-button {
    justify-content: center;
}

.back-button {
    justify-content: center;
    width: fit-content;
    color: #e15330;
    border-radius: 5px;
    padding: 4px 8px 4px 10px;
    background-color: rgb(226, 226, 226);
    font-weight: 700;
    font-size: 20px;
    margin: 0 15px 20px auto;
}

.welcome-text-mobile {
    font-family: "Gilroy";
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/* The animation code */
@keyframes example {
    from {
        opacity: 1.0;
    }
    to {
        opacity: 0.5;
    }
}


.hiddenBook {
    animation-name: example;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
}

.visibleBook {
    opacity: 1.0;
}

.chapterWrapper h1 {
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: none;
    font-size: 20px;
    margin-bottom: 10px;
    color: #e15330;
}

.chapterWrapper h4 {
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    text-transform: none;
    margin-bottom: 10px;
    font-size: 17px;
    color: #262626;
}

.questionInChapter label {
    text-transform: none;
}

.exerciseIdText {
    color: black;
    margin-left: 100px;
    margin-top: 10px;
    font-size: 16px;
    padding-bottom: 30px;
    position: absolute;
}

.bookNameInChapter {
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 30px;
    text-transform: uppercase;
    text-align: center;
}

.book-table {
    background-color: #fff;
    border-collapse: collapse;
    border-radius: 5px;
    color: #262626;
    width: 100%;
}

.book-table td,
.book-table tr {
    padding: 10px;
}

tr:nth-child(even) {
    background: #e6efe8;
    border-radius: 5px;
}

table a {
    color: #262626;
}

table a:hover {
    color: #262626;
}

.button-green {
    background-color: #cde1d1;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    color: #4e6e4d;
    font-weight: bold;
}

.button-submit {
    background-color: #e15330;
    padding: 5px 0px 5px 0px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    width: 100px;
    margin-top: 5px;
    margin-bottom: 50px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.button-green-bigger {
    background-color: #e15330;
    padding: 20px 10px 20px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 24px;
    width: 340px;
    margin-top: 35px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
}

.button-reading {
    background-color: #a8b3d8;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    color: #394857;
    font-weight: bold;
}

.REQUESTED {
    color: #e15330;
}

.PUBLISHED {
    color: #4e6e4d;
}

.figureholder {
    float: left;
    margin-right: 40px;
}

.deleteDialogWrapper {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100%;
    padding: 25vh 35%;
}

.deleteDialog {
    background-color: #fff;
    text-align: center;
    padding: 50px 20px;
    border-radius: 5px;
}

.deleteDialogContent {
    margin-bottom: 50px;
}

.editDialogWrapper {
    position: fixed;
    left: 0;
    top: -40px;
    background-color: rgba(0, 0, 0, 0.3);
    height: 109vh;
    width: 100%;
    padding: 15vh 15%;
}

.editDialogWrapper .form h3 {
    margin-top: -20px;
}

.editDialogWrapper .form {
    background-color: #fff;
    text-align: center;
    padding: 50px 20px;
    border-radius: 5px;
}

.editDialogContent {
    margin-bottom: 50px;
}

.modal-wrapper {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 10vh 20%;
}

.modal {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 30px 50px;
}

.modal-path {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--dark);
}

#para span {
    font-size: 20px;
    line-height: 40px;
}

#para {
    margin-bottom: 50px;
}

.pathOpened {
    display: flex;
    justify-content: align-left;
}

.pathOpened div {
    padding-right: 5px;
}

.pathOpened b {
    font-weight: 400;
}

.clickable:hover {
    text-decoration: underline;
    cursor: pointer;
}

.linkStyle {
    color: red;
    text-decoration: underline;
}

.closeX {
    color: var(--dark);
    align-self: flex-end;
}

.modal-path div {
    margin-left: 0;
}

.modalDesc {
    margin: 30px 0 50px 0;
}

.labelMark svg {
    float: left;
    position: relative;
    top: -2px;
}

mark.orange {
    color: #ff0000;
    background: none;
}

.question-card {
    margin-bottom: 10px;
}

.score {
    margin-bottom: 30px;
}

.score b {
    font-family: "Gilroy";
    font-size: 1.8rem;
    margin-left: 10px;
}

.score input {
    margin-left: 10px;
    font-size: 1rem;
    width: 5rem;
    display: inline;
}

.confirmScore {
    float: right;
}

.editAnswers {
    width: 100%;
}

.editQuestionWrap {
    overflow-y: scroll;
    max-height: 310px;
}

.editQuestionWrap::-webkit-scrollbar-track,
.modalScroller::-webkit-scrollbar-track,
textarea::-webkit-scrollbar-track {
    background-color: #fff;
}

.editQuestionWrap::-webkit-scrollbar,
.modalScroller::-webkit-scrollbar,
textarea::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

.editQuestionWrap::-webkit-scrollbar-thumb,
.modalScroller::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border: 2px solid #ddd;
    border-radius: 10px;
}

.saveAnswer {
    float: left;
    padding: 5px 5px;
    margin: 2px 5px 2px 0;
    border-radius: 5px;
    border: 2px solid #262626;
    cursor: pointer;
    background-color: #4e6e4d;
}

.deleteAnswer {
    float: left;
    padding: 5px 5px;
    margin: 2px 5px 2px 0;
    border-radius: 5px;
    border: 2px solid #262626;
    cursor: pointer;
    background-color: #e15330;
}

textarea {
    resize: none;
}

.readingAnswer textarea {
    width: 100%;
    margin-bottom: 0;
}

.readingAnswer label {
    color: #4e6e4d;
    margin-bottom: 20px;
}

.readingExamStudentAnswer {
    width: 100%;
    word-wrap: break-word;
}

.centerImage {
    width: 400px;
    margin-top: 15px;
}

.greenQuestion {
    color: #20ac13;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
}

.redQuestion {
    color: #ff2013;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
}

.otherQuestion {
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
}

.correctAnswer {
    font-weight: bold;
}

.incorrectAnswer {
    text-decoration: line-through;
}

.coverImage {
    width: 250px;
    margin-top: 15px;
}

.imageCaption {
    text-align: center;
    font-size: 0.8rem;
    color: #4e6e4d;
    margin-bottom: 15px;
}

.profile {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin: 100px auto auto;
    border-radius: 10px;
    padding: 25px 65px 40px 50px;
    border: 4px solid #e15330;
    border-radius: 13px;
    background-color: rgba(255, 255, 255);
}

.centerTable {
    text-align: center;
    margin-left: 100px;
    margin-right: 100px;
    table-layout:fixed;
}

.centerTable td {
    text-align: left;
    margin-left: 100px;
}

.feedbackTable {
    width: 100%;
    max-width: 930px;
    margin: 100px auto 50px;
    min-height: 1000px;
    padding: 30px;
    align-self: center;
    justify-self: center;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    text-align: center;
    border: 4px solid #e15330;
    border-radius: 13px;
    background-color: rgba(255, 255, 255);

}

.profileData {
    margin-left: 20px;
    flex: 1 0 30%;
    max-height: 50vh;
    font-weight: 600;
    overflow-y: auto;
    min-width: 50px;
}

.linkFont {
    cursor: pointer;
    color: #e15330;
    text-decoration: underline;
}

.bookFont {
    cursor: pointer;
    color: #e15330;
    text-decoration: underline;
    font-weight: bold;
}

.description {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 14px;
}

.specialwords {
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
}

.linebreak {
    height: 20px;
}

.exerciseTitle {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.exerciseSubtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 50px;
}

.profileGrades {
    margin-left: auto;
    margin-right: auto;
    flex: 1 0 30%;
    max-height: 800px;
    font-weight: 600;
    overflow-y: auto;
    min-width: 120px;
}

.profileGrades td {
    padding: 5px;
    min-width: 120px;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.profileGrades table {
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
}

.profileGrades table tr td:first-child {
    width: 30%;
    min-width: 280px;
}

.profileGrades table tr td:nth-child(3) {
    width: 30%;
    min-width: 280px;
    text-align: center;
}

.profileGrades table tr td i {
    font-weight: 400;
    opacity: 0.5;
}

.modalScroller {
    margin-top: 3vh;
    padding: 0 0 2vh 0;
    max-height: 64vh;
    overflow-y: auto;
}

.chapterWrapper {
    width: 80%;
    padding: 90px 10% 3% 10%;
    margin: auto;
    margin-bottom: 60px;
    background-color: #fff;

}

.chaptersWrapper {
    width: 300px;
    padding: 3% 4% 2% 3%;
    margin-bottom: 50px;
    background-color: #fff;
    border-style: solid;
    border: 4px solid #e15330;
    border-radius: 13px;
    display: grid;
    justify-content: center;
}

.storydescription {
    font-size: 18px;
    color: black;
    text-transform: none;
}

.chapterWrapper span {
    margin: 10px 0 16px 0;
    display: block;
}

.chapterWrapper p {
    display: block;
    margin-bottom: 20px;
}

.chapterWrapper a {
    background-color: transparent;
}

.chapterWrapper textarea {
    width: 100%;
    height: 400px;
}

.chapterWrapper .button-green {
    text-transform: uppercase;
    padding-bottom: 5px;
}

.questionInChapter {
    margin: 5px 0 20px 0;
}

.questionInChapter div {
    margin: 5px 0px 5px;
    width: fit-content;
    border-radius: 5px;
}

.questionInChapter label {
    margin: 0px 2px 0px 2px;
    position: relative;
    font-size: 16px;
    color: #262626;
    padding: 0px 0px 0px 5px;
    cursor: pointer;
}

.chaptersWrapper {
    max-width: 600px;
}

.chapterWrapper p span {
    font-size: 1em;
    line-height: 28px;
}

.chaptersWrapper a {
    background-color: transparent;
}

.chapterBody button {
    margin-top: 20px;
}

.chapterBody h1 {
    font-size: 23px;
    text-transform: initial;
    text-align: center;
}

.textarea-content {
    width: 100%;
    height: 250px;
}

#reading {
    padding: 30px;
    scroll-behavior: smooth;
    max-width: 750px;
    font-size: 20px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 200;
    margin: auto;
    color: #333;
    position: relative;
}

#reading .intro {
    margin-bottom: 20px;
    font-size: 13px;
    width: fit-content;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    border: 2px solid #fff;
    color: #fff !important;
    background-color: #4f6641;
    padding: 5px 10px 5px 10px;
}

#reading span {
    transition: 1s;
    transition-property: opacity;
}

.exerciseTypeSelection {
    background-color: #e15330;
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 13px;
    width: 160px;
    margin: -30px 20px 30px 0px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    border-color: black;
    border-style: solid;
    border-width: 2px;
}

.exerciseTypeUnselected {
    background-color: #9e361c;
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 13px;
    width: 160px;
    margin: -30px 20px 30px 0px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.bookdescription {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    font-size: 1.1em;
    font-weight: 400;
    font-family: 'Ropa Sans', sans-serif;
    text-transform: uppercase;
}

.booktitle {
    margin-left: 30px;
    margin-right: 12px;
    margin-top: 25px;
    font-size: 1.05em;
    font-weight: 400;
    font-family: 'Ropa Sans', sans-serif;
    text-transform: uppercase;
}

.book {
    transform-style: preserve-3d;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
    position: relative;
    height: 317px;
    cursor: pointer;
    backface-visibility: visible;
    transform-origin: left center;
}

.chapterInfo {
    position: relative;
    height: 280px;
    cursor: pointer;
    backface-visibility: visible;
}

.chapterContent {
    position: relative;
    backface-visibility: visible;
}

.front, .back, .page1, .page2, .page3, .page4, .page5 {
    position: absolute;
    transform-style: preserve-3d;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left center;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
}

.page5 {
    position: absolute;
    transform-style: preserve-3d;
    width: 30px;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left center;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
}

.front, .back {
    color: white;
}

.front, .page1, .page2, .page3, .page4, .page5 {
    border-bottom-right-radius: .9em;
    border-top-right-radius: .9em;
    box-shadow: 0 0 8px #ccc;
}

.binding {
    position: absolute;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left center;
    transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
    border-bottom-right-radius: .9em;
    border-top-right-radius: .9em;
    box-shadow: 0 0 8px #ccc;
    background-image: url(static/book-effect.png);
}

.page1, .page2 {
    background: #eee;
}

.page4 {
    background: #eee;
}

.page5 {
    background: #ccc;
}

.book:hover .binding {
    transform: rotateY(-120deg);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .front {
    transform: rotateY(-120deg);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page1 {
    transform: rotateY(-115deg);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page2 {
    transform: rotateY(-22deg);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page4 {
    transform: rotateY(-30deg);
    box-shadow: 0 1em 3em 0 rgba(0, 0, 0, .2);
}

.book:hover .page5 {
    transform: rotateY(-95deg);
}

.book:hover .back {
    transform: rotateY(-14deg);
}

.website-title {
    text-transform: uppercase;
    font-family: "Gilroy";
    font-weight: normal;
    margin-bottom: 10px;
    color: #e15330;
    font-size: 24px;
}

.website-info-mobile {
    display: none;
}

@media screen and (max-width: 1550px) {
    .books-display {
        position: relative;
    }

    .website-title {
        text-transform: uppercase;
        font-family: "Gilroy";
        font-weight: normal;
        margin-bottom: 10px;
        color: #e15330;
        font-size: 24px;
        text-align: center;
    }

    .books-display .levelinfo {
        margin: 0;
        top: -70px;
    }

    .books-display .levelinfo > div {
        position: relative;
        left: auto;
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 1450px) {
    .books-display {
        grid-template-columns: repeat(4, 250px);
        margin-left: 0;
        gap: 20px;
    }
}

@media screen and (min-width: 550px) and (max-width: 1100px) {
    .books-display {
        grid-template-columns: repeat(4, 200px);
        margin-left: 0;
        gap: 20px;
    }

    .readingModeSelection {
        display: none;
    }

    .front, .back, .page1, .page2, .page3, .page4, .page5 {
        width: 200px;
    }

    .coverImage {
        width: 200px;
        margin-top: 15px;
    }

    .binding {
        width: 200px;
    }

    .storydescription {
        font-size: 12px;
    }

    .book {
        height: 300px;
    }

    .booktitle {
        font-size: 14px;
    }

    /*.levelinfo {*/
    /*    max-width: 200px;*/
    /*}*/
}

@media screen and (max-width: 900px) {
    .books-display {
        grid-template-columns: repeat(2, 200px);
        margin-left: 0;
        gap: 50px;
    }

    .readingModeSelection {
        display: none;
    }

    .login-wrapper {
        border-radius: 22px;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 508px;
    }

    .login-wrapper {
        margin-top: 150px;
        min-height: 410px;
    }

    .website-info {
        display: none;
    }

    .center-image {
        background-repeat: no-repeat;
        background-size: 95%;
        height: 25em;
        display: inline-block;
        height: auto;
        min-height: 100%;
        margin-top: 5px;
        opacity: 1;
        display: block;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
    }

    .website-info-mobile {
        margin-top: -95px;
        display: block;
    }

    .mobile-image {
       display: block; 
    }

    .mobile-image {
        background-repeat: no-repeat;
        background-size: 95%;
        height: 25em;
        display: inline-block;
        max-width: 550px;
        margin-left: 5px;
        width: 45%;
        height: auto;
        min-height: 100%;
        margin-top: 5px;
        opacity: 1;
    }

    .login-form {
        width: 100%;
    }

    .header-links {
        margin-top: 5px;
        font-size: 20px;
    }

    .profileGrades table tr td {
        width: auto !important;
        min-width: auto !important;
    }

}

@media screen and (max-width: 700px) {

    .books-display {
        margin-top: 55px;
    }

    .chapterWrapper {
        width: 100% !important;
        padding: 90px 30px 30px !important;
    }

    .bookParent {
        padding-top: 90px;
    }

    .content-small {
        min-width: auto;
    }


}

@media screen and (max-width: 550px) {

    .books-display {
        margin-top: 100px;
    }

    .books-display {
        grid-template-columns: repeat(1, 250px);
        margin-left: 0;
    }

    .feedbackTable,
    .about-wrapper {
        border: none;
    }

    .website-title {
        display: none;
    }

    .books-display .levelinfo {
        top: -90px;
    }

    .levelinfo {
        max-width: 250px;
    }
    .table-responsive table{
        font-size: 10px;
    }
    .table-responsive h1 {
        margin: 0;
        padding: 0;
    }
}
