@media screen and (max-width: 550px) {
  .header {
    flex-direction: column;
    width: 100%;
  }
  .logo {
    padding-left: 0;
    padding-right: 0;
  }

  .header-links {
    padding-left: 0;
    padding-right: 0;

    .header-link,
    .selected-header-link {
      margin-left: 0.5rem;
      margin-right: 0.5rem;

    }
  }
  .login-wrapper {
    border: none;
  }
}

@media screen and (max-width: 380px) {
  .header-links {

    .header-link,
    .selected-header-link {
      font-size: 15px;
    }
  }
}
